.p-accordion-header-text{
    display: flex;
    align-items: center;
}

#quill-mention-list{
    border: 1px solid #e5e7eb;
    border-radius: 4%;
    background-color: white;
}
#quill-mention-list > li{
    cursor: pointer;
    padding: 5px;
}
#quill-mention-list > li:hover{
    background-color: #F9FAFB;
}