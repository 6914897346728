.dock-nav {
    background-color: #333;
    padding-bottom: 0.5rem;
}

.dock-tab-btn {
    background-color: #333;
    color: white;
    font-size: 18px;
    font-weight: 600;
}
.dock-top .dock-bar {
    background-color: #333;
}

.dock-ink-bar {
    background-color: #333;
}

.dock-hbox > .dock-divider{
    transform: scaleX(2);
}

.dock-divider{
    background-color: #C0C0C0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-three-dots-vertical' viewBox='0 0 16 16'> <path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/> </svg>");
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    /* Prevent the browser's built-in drag from interfering */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}