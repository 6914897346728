.formatterCell{
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
}

.tabulator-cell:has(div > div.red-bg) {
    background-color: indianred;
}

button.wrapIcon:hover > svg {
    stroke: white;
}

button.wrapIconFill:hover > svg {
    stroke: white;
    fill: white;
}

div.tabulator-row:has(div):has(div):has(div.manualPriority){
    color: red;
    font-weight: 600;
}

div.tabulator-row:has(div):has(div):has(div.duplicate){
    color: blue;
    font-weight: 600;
}

.ant-select-item-option{
    font-size: 16px!important;
}