.MuiTabScrollButton-root {
    background-color: #f9f9f9;
    color: #333;
}

.Mui-disabled {
    opacity: 1;
}

.MuiButtonBase-root {
    background-color: #f9f9f9;
    color: #333;
    height: 48px;
}

.MuiBox-root{
    /*background-color: #18181B;*/
    width: 100%;
}

.MuiTabs-root{
    height: 48px;
}

.MuiTabs-flexContainer{
    height: 48px;
    align-items: center;
    display: flex;
}

.MuiMenuItem-root{
    background-color: #333;
} 
.MuiPaper-root{
    background-color: #333;
    border-radius: 0;
} 

.MuiIconButton-root{
    background-color: #333;
    color: #f9f9f9;
    border-radius: 0%;
    height: 40px;
}

.MuiTabPanel-root{
    padding: 0px;
}

.MuiButtonBase-root.MuiTab-root{
    background-color: #f9f9f9;
    color: #333;
    border-right: 1px solid #d1d1d1;
    font-weight: 550;
}

.MuiButtonBase-root.MuiTab-root:not(:first-child) {
    flex-grow: 1;
    justify-content: space-between;
}

.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: black;
    background-color: #d1d1d1;
}

.MuiTabs-indicator{
    width: 0;
    height: 0;
}

/*.MuiList-root{
    width: 180px;
    height: 200px;
}*/