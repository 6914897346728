.tabulator .tabulator-header .tabulator-col {
    background-color: lightgray;
    color: black;
    font-weight: 700;
    font-size: medium;
}

.tabulator {
    border: 1;
}

.tabulator-row .tabulator-cell {
    padding: 7px!important;
    font-size: 15px;
    vertical-align:unset;
}

/*.tabulator-row{
    height: 30px!important;
}*/


.tabulator-row.tabulator-row-even {
background-color: white;
}

.tabulator .tabulator-header .tabulator-col:first-child {
    text-align: center;
}

.tabulator-print-header, tabulator-print-footer{
    text-align:center;
    font-size: 1.875rem; /* 30px */
    line-height: 2.25rem; /* 36px */
    font-weight: 600;
    color: black;
}
.mypanel .p-dialog-content {
    /*background-color: transparent!important;*/
    /*box-shadow: none;*/
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: auto;
    padding: 15px!important;
    border-radius: 15px!important;
    /*flex-grow: 0.5!important;*/
}

-webkit-scrollbar:horizontal {
    height: 11px;
}

.p-slider .p-slider-range,span.p-slider-handle.p-slider-handle-active:hover {
    background-color: #1976D2;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: unset;
}

.p-dropdown:not(.p-disabled).p-focus {
    border-color: unset;
}

.p-focus {
    border-color: unset;
}

.p-button.p-button-danger{
color: #ffffff;
background: #ef4444;
border: 1px solid #ef4444;
}

.p-button{
    color: #ffffff;
    background: #6366f1;
    border: 1px solid #6366f1;
    }

.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    padding: 12px 20px;
    width: auto;
}

.p-orderlist {
    flex-direction: column !important; 
}

.p-orderlist-controls {
    flex-direction: row !important;
}

.p-orderlist-controls > button {
    color: #ffffff;
    background: #1d4ed8;
    border: 1px solid #1d4ed8;
    padding: 5px;
    margin: 8px
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #d1d5db;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #4b5563;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
    border-color: #1d4ed8;
    background: #1d4ed8;
    color: #ffffff;
}

.p-toast .p-toast-message.p-toast-message-info {
    background: rgba(219, 234, 254, 1);
}

.p-menuitem-text,.p-menuitem-text:hover,.p-menuitem-text:active{
    color: white;
    background-color: #333;
}
.p-menuitem-icon,.p-menuitem-icon:hover,.p-menuitem-icon:active{
    color: white;
    background-color: #333;
}
.p-submenu-icon,.p-submenu-icon:hover,.p-submenu-icon:active{
    color: white;
    background-color: #333;
}
.p-menuitem-content,.p-menuitem-content:hover,.p-menuitem-content:active{
    background-color: #333;
}

.p-menubar-root-list {
    width: 180px!important;
}
.p-panelmenu-content{
    color: white;
    background-color: #333;
}

.p-datepicker-title{
    display:contents;
}

.p-datepicker-trigger{
    padding: 0.3rem;
}

span.p-calendar > input{
    padding-left: 16px;
}

.p-overlaypanel-content{
    padding: 0px;
}

div:has(> div > div.training_name){
    display: inline-flex!important;
}

.tabulator-data-tree-control{
    margin-top: 5px!important;
    margin-left: 20px!important;
}

.tabulator-data-tree-branch{
    margin-top: 5px!important;
    margin-left: 22px!important;
}

.p-selectbutton .p-button {
    background: #ffffff;
    border: 1px solid #d1d5db;
    color: #4b5563;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 0.5rem 1rem;
}

.p-selectbutton .p-button.p-highlight {
    background: #1d4ed8;
    border-color: #1d4ed8;
    color: #ffffff;
}

.p-selectbutton.p-buttonset > div.p-button.p-highlight {
    background: #717d8f;
    border-color: #717d8f;
}

.p-selectbutton.p-buttonset > div+div.p-button.p-highlight {
    background: #1d4ed8;
    border-color: #1d4ed8;
}

.p-inputtext:enabled:focus,.p-inputtext:enabled::after{
    box-shadow: none;
}

.p-multiselect-items-label{
    text-wrap: auto;
}

.p-chips.p-disabled > ul > li.p-chips-token{
    background-color: #3B82F6;
    color: #ffffff;
    margin-bottom: 5px;
}

/*@media screen and (max-width: 1024px){
    .p-menubar-root-list {
        width: 150px!important;
    }
}*/
    