  .p-fileupload-row{
    width: 300px;
    float: left;
    height: 150px;
  }

  div:has(div.p-fileupload-row){
    overflow: auto;
  }

  .p-fileupload-content{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .p-fileupload{
    border: 1px solid #ccc;
    border-radius: 0;
  }

  .ql-tooltip{
    position: absolute!important;
    left: 0!important;
    top: 5px!important;
    z-index: 10;
  }

  .ql-container.ql-snow{
    font-size: 16px;
  }