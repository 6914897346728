.Block{
    float: left;
    width: 30%;
    height: 80vh;
    display: flex;
    align-items: center;
    margin-left: 20px;
}
#Resizable{
    margin-top: 50px;
    border: 1px solid black;
    width: 100%;
    height: inherit;

}
#Draggable{
    background: rgba(1, 1, 1, 0.2);
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    cursor: col-resize;
    height: 20px;
    width: 10px;
}

@media screen and (max-width: 600px) {
    .Block {
      width: 100%;
    }
  }

.row:after {
    content: "";
    display: table;
    clear: both;
  }